
import Test from "../components/TestBatch/TestData"

const TestBatchPage = () => {
  return (
    <div>
    <h1 className='text-black text-center flex justify-center  mt-20 font-extrabold text-4xl sm:text-6xl '>Test Batches & Current Affairs</h1>
   <Test isTrue={false}/>
    </div>
  )
}

export default TestBatchPage;