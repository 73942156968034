import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios'
import PDFVles from './PDFVIes'

const ViewMaterial = () => {
  

  const [ fileData, setFileData] = useState([])
  const {id} = useParams()


// Fetch file data by ID
useEffect(() => {
  if (id && fileData.length === 0) {
    fetchFileByID();
  }
}, [fileData]);

const fetchFileByID = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKENDURL}/materialsgetbyId/${id}`, {
      responseType: 'arraybuffer', // Important for downloading files
    });
    
    const fileBlob = new Blob([response.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(fileBlob);
    
    // Trigger the download or open in a new window
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = 'downloaded-file.pdf'; // You can customize the file name if needed
    link.click();
  } catch (err) {
    console.error("Error fetching file data", err);
  }
};

  const base64Data = fileData?.data

  


  return (

    <div  className="relative bg-white text-center sm:mt-10 h-[600px] sm:h-[auto] mt-0 px-5 pt-20 sm:pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-[800px] sm:rounded-lg sm:px-10">
    
    {/* <PDFVles base64={base64Data}/> */}
    {/* <h1>You didn't Have any Purchase, Go to Test Batch And Purchase Something</h1>
    <div className=' sm:hidden flex justify-center  mt-10'>
        <Link to="/testbatch">
        <button className='text-lg  font-Rahul1 hover:bg-white bg-gradient-to-r w-[200px] from-yellow-300 to-yellow-600 p-3 h-13 rounded-lg '>Test Batch</button>
        </Link>
        </div> */}
        <div>
            <h2>{fileData?.title}</h2>
           
        <iframe
          src={`data:application/pdf;base64,${base64Data}#toolbar=0`}
          type="application/pdf"
          width="100%"
          height="1000px"
          title={fileData?.title}
        
/>
        </div>
    </div>
   

  )
}

export default ViewMaterial 