import React, { useContext, useEffect, useState } from 'react'
import DataContext from '../../util/DataContext'
import { Link, useParams } from 'react-router-dom'
import QuizTestData from './QuizTestData'
import MaterialData from './MaterialData'
import axios from 'axios'


const SelectMaterial = () => {
    const {userData, token} = useContext(DataContext) 
    const product = userData.purchasedProducts

  return (
      <>
    {!product?.length >= 1 ?
    <div className="relative bg-white text-center sm:mt-20 h-[600px] sm:h-[100px] mt-0 px-5 pt-20 sm:pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-[800px] sm:rounded-lg sm:px-10">
    <h1>You didn't Have any Purchase, Go to Test Batch And Purchase Something</h1>
    <div className=' sm:hidden flex justify-center  mt-10'>
        <Link to="/testbatch">
        <button className='text-lg  font-Rahul1 hover:bg-white bg-gradient-to-r w-[200px] from-yellow-300 to-yellow-600 p-3 h-13 rounded-lg '>Test Batch</button>
        </Link>
        </div>
    </div>
    :<MaterialData userData={userData}/>}
    </>
  )
}

export default SelectMaterial