import React from 'react'
import {useLoaderData} from "react-router-dom" 
import TestDetails from "../components/TestBatch/TestDetails"
const TestDetailsPage = ({isTrue}) => {
    const testData = useLoaderData()
  return (
    <div>
        <TestDetails isTrue={isTrue} item={testData} />
    </div>
  )
}
export const testDetailPageLoader = async({params}) => {
    const id = params.id;
    const response = await fetch(process.env.REACT_APP_BACKENDURL+"/testbatch/" + id)
    
    if(!response){
        throw new Error("Details Not Found")
    }
    const jsonResponse = await response.json()


    return jsonResponse
}
export default TestDetailsPage