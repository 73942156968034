import React, { useCallback, useContext, useEffect, useState } from 'react';
import DataContext from '../../util/DataContext';
import axios from 'axios';
import { Link } from 'react-router-dom';

const ShowSelectedMaterial = ({ status, item, selectedUnit }) => {
  const { userData } = useContext(DataContext);
  const [materialData, setMaterialData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  const fetchData = useCallback(async () => {
    try {
      setLoading(true); // Set loading true when fetching starts
      setError(null); // Clear any previous errors
      const apiUrl = process.env.REACT_APP_BACKENDURL + "/materials";
      const response = await axios.get(`${apiUrl}/${selectedUnit}`);
      setMaterialData(response.data);
    } catch (err) {
      setError("Failed to fetch materials. Please try again.");
      console.error("Error fetching data:", err);
    } finally {
      setLoading(false); // Set loading false after fetch completes
    }
  }, [selectedUnit]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : materialData?.length > 0 ? (
        materialData.map((item) => (
          <ul key={item._id}>
          <Link to={`/purchased-material/${item._id}`}>
          <li className='mt-3 list-disc text-lg ml-4 text-blue-500'>{item.title}</li>
          </Link>
          </ul>
        ))
      ) : (
        <p>No Data Found</p>
      )}
    </div>
  );
};

export default ShowSelectedMaterial;
