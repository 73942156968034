import React, { useCallback, useEffect, useState } from 'react'
import ShowAllTest from './ShowAllTest'
import axios from 'axios'
import ShowSelectedMaterial from "./ShowSelectedMaterial"

const SelectedMaterial = ({data, setShowAllTests, status}) => {

    const [selectedUnit, setSelectedUnit] = useState(null); 
    const svg1 = <svg  className="h-6 w-6"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor" >
    <path
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={2}
    d="M6 18L18 6M6 6l12 12"/>
    </svg>
   
    // const testListData = (data.testData)
    const closeHandler = () => {
      setShowAllTests(prevValue => !prevValue)
    }
    const [productData, setProductData] = useState([])
    const id = data._id
     
    const fetchData = useCallback(async () => {
      try {
          const apiUrl = process.env.REACT_APP_BACKENDURL + "/material-unit";
          const response = await axios.get(`${apiUrl}/${id}`);
          setProductData(response.data);
      } catch (error) {
          console.log("error", error);
      }
  }, [id]); // Include `id` as a dependency if it can change

  useEffect(() => {
      if (productData.length === 0) {
          fetchData();
      }
  }, [productData, fetchData]);
  
  const toggleUnit = (unitId) => {
    // Toggle the dropdown
    setSelectedUnit(selectedUnit === unitId ? null : unitId);
  };

  return (
  <> 
      <div>
      <div className="flex justify-between">
        <h1 className="text-[25px] text-pink-600 font-bold my-4 sm:ml-4">Unit List</h1>
        <button
          onClick={closeHandler}
          className="bg-pink-600 mt-5 h-8 hover:bg-pink-500 rounded-md text-white"
        >
          {svg1}
        </button>
      </div>

      {productData.map((item) => (
        <div key={item._id} className="my-2">
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={() => toggleUnit(item._id)} // Toggle dropdown on click
          >
            <h1 className="text-[25px] font-bold my-4 sm:ml-4">{item.unitName}</h1>
            <span className="text-sm text-gray-500">
              {selectedUnit === item._id ? "▲" : "▼"}
            </span>
          </div>

          {selectedUnit === item._id && (
            <div className="ml-6">
              <ShowSelectedMaterial selectedUnit={selectedUnit} status={status} item={item} />
            </div>
          )}
        </div>
      ))}
    </div>
    </>
  )
}

export default SelectedMaterial