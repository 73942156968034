import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { getAuthToken } from '../../util/auth'
import Dropzone from "react-dropzone";
import { errorToast, successToast, warningToast } from '../../util/toaster'
import Select from 'react-select'
import { PDFDocument } from 'pdf-lib'; 

const AddMaterialList = ({setAddTest, id }) => {

  const [title, setTitle] = useState("")
  const [unitId, setUnitId] = useState("")
  const [question, setQuestion] = useState("")
  const [calink, setCaLink] = useState("")
  const [files, setFiles] = useState([])
  const [uploadedFile, setUploadedFile] = useState(null);
  const [materialUnitList, setMaterialUnitList] = useState([])

  useEffect(()=> {
    if(materialUnitList.length === 0){
      fetchMaterialUnits()
    }
  }, [id, materialUnitList])

  const fetchMaterialUnits = async() => {
    try{
      const response = await axios.get(process.env.REACT_APP_BACKENDURL+`/material-unit/${id}`)
      if(response.status === 200){
        setMaterialUnitList(response.data)
        return
      }
    }catch(err){
      console.log(err)
    }
    
  }

    const svg1 = <svg  className="h-6 w-6"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor" >
      <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6 18L18 6M6 6l12 12"/>
      </svg>
    const labelClass = 'justify-start font-medium'
    const inputClass = " font-medium border-solid active:border-lime-600 pl-2 mt-2 w-[100%] justify-stretch block border-cyan-500 border-2 rounded-md"

    const closeHandler = () => {
      setAddTest(prevValue => !prevValue)
    }
    // Patch the Test to Product
    const token = getAuthToken()
    const apiUrl = process.env.REACT_APP_BACKENDURL+`/testbatch/updateTestList/${id}`
    const patchTest = {testData: {
      testTitle: title ,
      unitId: unitId,
      driveLink: question,
      calink:calink
  }}
    // const submitHandler = async(e) => {
    //   e.preventDefault()
    //   try{
    //     const response =await axios.patch(apiUrl,patchTest,{
    //       headers:{
    //           'Content-Type': "application/json",
    //           'Authorization': 'Bearer '+ token 
    //       }
    //       })
    //       closeHandler()
    //       return response;
          
    //   }catch(error){
    //     console.error('Error making PATCH request:', error);
    //   }
      
    // }


    const handleDropChange = (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) => ({
        name: file.name,
        type: file.type,
        file: file,
        preview: URL.createObjectURL(file), 
      }));
      setFiles(newFiles); 
      setUploadedFile(newFiles[0]); 
    };
  


   const handleUpload = (e) => {
    e.preventDefault()
      if (!uploadedFile) {
        warningToast("No file to upload.");
        return;
      }

      const formData = new FormData();
      formData.append('file', uploadedFile.file); // Use the compressed file
      formData.append('title', title);
      formData.append('unitId', unitId);

      const uploadUrl = `${process.env.REACT_APP_BACKENDURL}/material`;

      axios.post(uploadUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      })
      .then(response => {
        setFiles([]);
        setUploadedFile(null);
        successToast('File uploaded successfully.');
      })
      .catch(error => {
        errorToast('File upload failed.');
      });
    };

    const options = materialUnitList.map((item)=> ({
      value : item._id,
      label : item.unitName
    }))

    const baseStyle = {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      borderWidth: 2,
      borderRadius: 2,
      borderColor: '#eeeeee',
      borderStyle: 'dashed',
      backgroundColor: '#fafafa',
      color: '#bdbdbd',
      outline: 'none',
      transition: 'border .24s ease-in-out'
    };
  
    return (
    <>
        <form onSubmit={handleUpload} className='absolute sm:inset-5 w-[90%] h-[410px] sm:w-[600px] sm:h-[425px] bg-white mt-10 px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg sm:px-10'>
            <div className='flex justify-end'>
            <button onClick={closeHandler} className='bg-pink-600 w-6 hover:bg-pink-500 rounded-md text-white'>{svg1}</button>
            </div>
            <label className={labelClass}>Topic Name</label>
            <input onChange={(e)=> setTitle(e.target.value)} className={inputClass} type='text'></input>
            <br></br>
            <label  className={labelClass}>Select Unit</label>
            <Select  options={options} onChange={(e)=> setUnitId(e.value)}/>
            <br></br>
            
            <label className={labelClass}>PDF Upload</label>
            
            <Dropzone 
               accept="application/pdf"
                multiple={false} onDrop={(acceptedFiles) => handleDropChange(acceptedFiles, setFiles)} maxSize={10485760}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()} style={baseStyle}>
                        <input {...getInputProps()} />
                        {files.length === 0 && (
                          <div className="dz-message">
                            <span className="dz-message-text">Drag and drop file</span>
                            <span className="dz-message-or"> or </span>
                            <button color="primary"> SELECT</button>
                          </div>
                        )}
                        {files.map((file) => (
                      
                          <div
                            key={file.name}
                            className="dz-preview dz-processing dz-image-preview dz-error dz-complete"
                          >
                            <div className="dz-image">
                              {/* <img src={file.preview} alt="preview" /> */}
                              <iframe src={file.preview} name="iframe_a"
                                height="100%" width="50%" title="Iframe Example">
                              </iframe>
                            </div>
                          <div style={{marginTop:"20px"}}>
                            <span >{file.name}</span>
                          </div>
                          </div>
                        ))}
                      </div>
                    </section>
                  )}
                </Dropzone>
            <button className='mt-4 w-full bg-pink-600 hover:bg-pink-500 text-white rounded-lg h-9'>Submit</button>
        </form>
    </>
  )
}

export default AddMaterialList