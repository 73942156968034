
import Test from "../components/TestBatch/TestData"

const MaterialPage = () => {
  return (
    <div>
    <h1 className='text-black text-center flex justify-center  mt-20 font-extrabold text-4xl sm:text-6xl '>Study Material</h1>
   <Test isTrue={true}/>
    </div>
  )
}

export default MaterialPage;