import React from 'react'
import SelectMaterial from '../components/Quiz/SelectMaterial'
import { useParams } from 'react-router-dom'
import ViewMaterial from './ViewMaterial'


const PurchasedMaterial = () => {

  return (
    <div>
      <SelectMaterial/>
    </div>
  )
}

export default PurchasedMaterial