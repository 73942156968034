import React, { useContext, useState } from 'react'
import DataContext from '../../util/DataContext'
import { getAuthToken } from '../../util/auth'
import axios from 'axios'
import NotLoggedIn from '../Responses/NotLoggedIn'
import AddTestList from './AddTestList'
import SellTest from './SellTest'
import CreateOnlineTest from './CreateOnlineTest'
import { successToast } from '../../util/toaster'
import CreateMaterial from './CreateMaterial'
import { redirect, useNavigate, useParams } from 'react-router-dom'
import AddMaterialList from './AddMaterialList'

const TestDetails = ({item, isTrue}) => {
const {userData} = useContext(DataContext)
const [notLoggedIn, setNotLoggedIn] = useState(false)
const [addTest, setAddTest] = useState(false)
const [createOnlineTest, setCreateOnlineTest] = useState(false)
const [showSell, SetShowSell] = useState(false)
const [userId, setUserId] = useState("")

const navigate = useNavigate()
const handleNavigate = () => {
    if(item.status === "material"){
        navigate("/purchased-material")
        return
    }else{
        navigate("/yourpurchases")
        return
    }
}
// for Patch
const apiUrl = process.env.REACT_APP_BACKENDURL+`/user/updateProduct/${userId}`
const patchData = {
    "purchasedProducts" : item._id
}
const token = getAuthToken()
const updateProduct = async(e) => {
    try{
        const response = await axios.patch(apiUrl, patchData, {
            headers:{
                'Content-Type': "application/json",
                'Authorization': 'Bearer '+ token 
            }
            })
    
        if(response.status === 200){
            SetShowSell(false)
            successToast("Added Successfully")
        }
       return response;
       
    }catch(error){
        console.error('Error making PATCH request:', error);
    }
}

const sellTestHandler = () => {
    if(token){
        updateProduct()
    }
    else{
        setNotLoggedIn(true)
    }
}

const handleUserSelect = (userId) => {
    console.log('Selected user ID:', userId);
    setUserId(userId)
    // Add your logic to handle the selected user here
  };

// Admin Access
    let isAdmin;
    if(token && userData.role === "admin"){
        isAdmin = true
    }
const addTestHandler = () => {
    setAddTest(true)
}
const CreateOnlineTestHandler = () => {
    setCreateOnlineTest(true)
}

const phoneNumber = "+917598480189"
const message = `Hello, I am Register for the ${item.title}, 
Price:${item.price} 
Name:${userData.name} 
Phone:${userData.phone} `;
// Get Client Intrested Product Data
    const registerHandler = () => {
        if(token){
                window.open(`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`, '_blank');
        }else{
            setNotLoggedIn(true)
        }
    }
    const {id} = useParams();
    let isPurchased = false;
    isPurchased = userData.purchasedProducts?.includes(id)

  return (
    <>
    <div className='sm:my-[100px]'>
        
        <div  className='sm:relative  bg-white px-6 pt-10 pb-8 shadow-xl  sm:mx-auto sm:max-w-[1000px] max-w-[100%] sm:rounded-lg sm:px-10'>
        {showSell && <SellTest item={item} SetShowSell={SetShowSell} onSubmit={sellTestHandler} onSelect={handleUserSelect} userData={userData} setAddTest= {setAddTest}/>}
        {addTest
            ? (!isTrue 
                ? <AddTestList id={item._id} setAddTest={setAddTest}/> 
                : <AddMaterialList id={item._id} setAddTest={setAddTest}/>
                ) 
            : ""
            }

        {createOnlineTest 
            ? (!isTrue 
                ? <CreateOnlineTest id={item._id} setAddTest={setCreateOnlineTest}/> 
                : <CreateMaterial id={item._id} setAddTest={setCreateOnlineTest}/>
                ) 
            : ""
            }
        {notLoggedIn && <NotLoggedIn/>}
        {isAdmin? 
        <>
        <div className='flex justify-end mb-5'>
        <button  onClick={CreateOnlineTestHandler} className='text-md m-2 font-roboto bg-green-500 text-white w-[100%] sm:w-[20%] h-8 sm:h-10 rounded-[5px] hover:bg-green-600'>{`${isTrue? "Create Unit" :"Create Online Test"}`}</button>
        </div>
        <div className='flex justify-end'>
        
        <button onClick={()=>SetShowSell(true)}  className='text-md m-2 bg-pink-500 text-white w-16 rounded-[5px] hover:bg-pink-600'>Sell</button>
        <button onClick={addTestHandler} className='text-md m-2 font-roboto bg-blue-500 text-white w-20 rounded-[5px] hover:bg-indigo-600'>{`${isTrue? "Add PDF" :"Add Test"}`}</button>
        <button className='text-md m-2 bg-blue-500 text-white w-20 rounded-[5px] hover:bg-indigo-600'>Delete</button>
        </div>
        
        </>
        : null }
            <div className='grid sm:grid-cols-2'>     
                <div className='m-1 sm:m-3 sm:mt-10'>
                    <img className='rounded-xl shadow-xl w-[100%]' alt={item.title} src={item.imageUrl}></img>
                </div>
                <div className='sm:ml-10 ml-2 text-left '>
                    <h1 className='text-[35px] m-1  sm:m-3 sm:mt-10 font-Roboto '>{item.title}</h1>
                    <p className='text-[30px] m-1 sm:m-3 text-blue-600 font-Roboto'>Price Rs.{item.price}</p>
                    <h3 className='text-[20px] m-1  sm:m-3 font-Roboto '>Rating: ⭐{item.rating}</h3>
                  
                    {
                    !isPurchased ? 
                    <button onClick={registerHandler} className='bg-yellow-400 hover:bg-yellow-200 my-3 text-lg w-[140px] font-Rahul1 sm:mx-3 h-[40px] rounded-md'>Register Now</button> 
                    : <button onClick={handleNavigate} className='bg-yellow-400 hover:bg-yellow-200 my-3 text-lg w-[140px] font-Rahul1 sm:mx-3 h-[40px] rounded-md'>View</button>
                    }
                        
                        
                    <h2 className='text-lg m-3 sm:m-3  font-Rahul1'><span dangerouslySetInnerHTML={{ __html: item.description}} /></h2>
                </div>
            </div>
            
        </div>
    </div>
  
    </>
  )
}

export default TestDetails