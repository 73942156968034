import axios from 'axios'
import React, { useState } from 'react'
import { getAuthToken } from '../../util/auth'
import { errorToast, successToast, warningToast } from '../../util/toaster'


const CreateMaterial = ({setAddTest, id }) => {
  const [unitName, setUnitName] = useState("")
  const [unitNo, setUnitNo] = useState(0)
  

    const svg1 = <svg  className="h-6 w-6"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor" >
      <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6 18L18 6M6 6l12 12"/>
      </svg>
    const labelClass = 'justify-start font-medium'
    const inputClass = " font-medium border-solid active:border-lime-600 pl-2 mt-2 w-[100%] justify-stretch block border-cyan-500 border-2 rounded-md"

    const closeHandler = () => {
      setAddTest(prevValue => !prevValue)
    }
    // Patch the Test to Product
    const token = getAuthToken()
    const submitHandler = async(e) => {
      e.preventDefault()
    
    const apiUrl = process.env.REACT_APP_BACKENDURL+`/material-unit`
    const createTest = {
      unitName: unitName,
      unitNo : unitNo,
      materialId:id
  }
      try{
        const response =await axios.post(apiUrl,createTest,{
          headers:{
              'Content-Type': "application/json",
              'Authorization': 'Bearer '+ token 
          }
          })
          successToast("Unit Created Successfully")
          closeHandler()
          return response;
          
      }catch(error){
        console.error('Error making Post request:', error);
      } 
    }
   
    
    return (
    <>
        <form onSubmit={submitHandler} className='absolute sm:inset-5 w-[90%] h-[350px] sm:w-[600px] sm:h-[350px] bg-white mt-10 px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-lg sm:rounded-lg sm:px-10'>
            <div className='flex justify-end'>
            <button onClick={closeHandler} className='bg-pink-600 w-6 hover:bg-pink-500 rounded-md text-white'>{svg1}</button>
            </div>
            <label className={labelClass}>Unit Name</label>
            <input onChange={(e)=> setUnitName(e.target.value)} className={inputClass} type='text'></input>
            <br></br>
            <label className={labelClass}>Unit No</label>
            <input onChange={(e)=> setUnitNo(e.target.value)} className={inputClass} type='number'></input>
            <br></br>
            {/* <label className={labelClass}>No.of Questions</label>
            <input onChange={(e)=> setNoQuestions(e.target.value)} className={inputClass} type='text'></input>
            <br></br> */}
            {/* <label className={labelClass}>Answer Key Link</label>
            <input onChange={(e)=> setAnswerKey(e.target.value)} className={inputClass} type='text'></input>
            <br></br> */}
           
            {/* <label className={labelClass}>Current Affairs Link</label>
            <input onChange={(e)=> setCaLink(e.target.value)} className={inputClass} type='text'></input>
            <br></br> */}
            <button className='mt-4 w-full bg-pink-600 hover:bg-pink-500 text-white rounded-lg h-9'>Submit</button>
        </form>
    </>
  )
}

export default CreateMaterial